(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_flickity() {
            $.lib_flickity(function(){

                let slider = selector.find("[data-slider]");

                slider.flickity({
                    wrapAround: true,
                    pageDots: false,
                    autoPlay: selector.find('[data-slider]').data("slider"),
                    pauseAutoPlayOnHover: true,
                    on: {
                        ready: function() {
                            slider.find(".flickity-button").nl_lib_ripple();
                        }
                    }
                });
            });

            fns.fn_flickity = fn_flickity;
        })();

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_base_gallery");