(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_flickity() {
            $.lib_flickity(function(){

                let slider = selector.find("[data-slider]");

                slider.flickity({
                    groupCells: '25%',
                    cellAlign: 'left',
                    contain: true,
                    wrapAround: true,
                    pageDots: false,
                    draggable: false,
                    autoPlay: selector.find('[data-slider]').data("slider"),
                    pauseAutoPlayOnHover: true,
                    on: {
                        ready: function() {
                            slider.find(".flickity-button").nl_lib_ripple();
                        }
                    }
                });
            });

            fns.fn_flickity = fn_flickity;
        })();

        (function fn_tooltipster() {
            $.importScript(cdnjs.tooltipster, function(){
                selector.find("[data-tooltip]").each(function(){
                let elm = $(this);
                    elm.tooltipster({
                        animation: "custom",
                        interactive: true,
                        delay: 500,
                        maxWidth: 320,
                        content: elm.data("tooltip"),
                        contentAsHTML: true
                    });
                });
            });

            fns.fn_tooltipster = fn_tooltipster;
        })();


        (function fn_nav_scroll() {
            function fn_scroll() {
                let offset = (win.height() / 2);
                let scroll = doc.scrollTop() + offset;

                selector.find(".elm_text_inner").each(function () {
                    let el = $(this),
                        index = el.data("index"),
                        nav = selector.find(".elm_text_nav [data-index]"),
                        nav_index = selector.find(".elm_text_nav [data-index="+index+"]");

                    if (el.position().top <= scroll - win.height() && !nav_index.hasClass("state--active")) {
                        nav.removeClass("state--active");
                        nav_index.addClass("state--active");
                        el.closest(selector).find(".elm_text_inner").removeClass("state--active");
                        el.addClass("state--active");
                    }
                });
            }

            if (stickySupport) {
                win.on("scroll", fn_scroll);

                fn_scroll();
            } else {
                html.addClass("no-sticky");
            }

            fns.fn_nav_scroll = fn_nav_scroll;
        })();

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_block_benefits");