(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    nl_lib_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s[0]).length) {
                window.lui.lib[key](dialog_selector.find(s[0]),s[1])
            }
        });
    });

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    $.libInit(".part_ui_btn", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit(".part_ui_input textarea", "lib_textarea", function(selector) {
        if ($(selector).length) {
            $.importScript(cdnjs.autosize, function(){
                cssLoaded(function(){
                    autosize($(selector));
                });
            });
        }
    });

    $.libInit(".part_ui_checkbox", "lib_checkbox", function(selector) {
        if ($(selector).children("span").text().length > 0) {
            $(selector).children("span").addClass("padding--left");
        }
    });

    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    sr.sync();
                });
            }
        });
    });

    if (body[0].style["mix-blend-mode"] === undefined) {
        html.addClass("no-blend-mode")
    }
    
    let picture = document.createElement('picture'),
        strObj = '' + picture;

    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
        $.importScript(cdnjs.picturefill);
    }

    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }
    
    $('.part_form_contact').antiSpam();
})(jQuery);