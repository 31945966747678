(function($){
    const fn = $(".comp_block_quotes");

    if (fn.length) {
        let slider = fn.find("[data-slider]");

        $.lib_flickity(function(){
            if (slider.length) {
                slider.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    adaptiveHeight: true,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    autoPlay: 8000,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: false,
                    draggable: true
                });
            }
        });
    }
})(jQuery);