(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};
        let puzzle = selector.find(".wrp_comp_puzzle");
        let body = selector.find(".wrp_comp_body");

        let timeout;

        window.lui.answer_dialog = {
          "open": 0,
          "selected": [0,0,0,0,0,0]
        };

        $.importScript(cdnjs.tooltipster, function(){
            selector.find("[data-answer-dialog]").each(function(){
                let elm = $(this);
                elm.tooltipster({
                    animation: "custom",
                    interactive: true,
                    delay: 500,
                    maxWidth: 320,
                    content: selector.find(".elm_puzzle_tooltip").clone(),
                    contentAsHTML: true,
                    trigger: 'click',
                    functionBefore: function() {
                        window.lui.answer_dialog["open"] = elm.data("answer-dialog");
                    }
                });
            });
        });

        doc.on("click", "[data-answer-select]", function(){
            let elm = $(this);
            let id = elm.data("answer-select");
            let text = elm.text();
            let selector = $(".comp_base_gallery_puzzle");

            if (id === 0) {
                window.lui.answer_dialog["selected"][window.lui.answer_dialog["open"]] = 0;
            }
            else if (id === 1) {
                window.lui.answer_dialog["selected"][window.lui.answer_dialog["open"]] = 1;
            }
            else if (id=== 2) {
                window.lui.answer_dialog["selected"][window.lui.answer_dialog["open"]] = 2;
            }
            else if (id === 3) {
                window.lui.answer_dialog["selected"][window.lui.answer_dialog["open"]] = 3;
            }
            else if (id === 4) {
                window.lui.answer_dialog["selected"][window.lui.answer_dialog["open"]] = 4;
            }
            else if (id === 5) {
                window.lui.answer_dialog["selected"][window.lui.answer_dialog["open"]] = 5;
            }

            selector.find(`[data-text="${window.lui.answer_dialog["open"]}"]`).text(text).addClass("state--active").tooltipster('close');
            selector.find(`[data-answer-area] [data-answer-dialog="${window.lui.answer_dialog["open"]}"]`).removeClass("state--active").tooltipster('close');
        });

        selector.on("click", "[data-confirm-puzzle]", function(){
            let elm = $(this);
            let notice = elm.closest(".wrp_comp_puzzle").find(".part_ui_alert");

            if (window.lui.answer_dialog["selected"].toString() === [0,1,2,3,4,5].toString()) {
                setTimeout(function(){
                    puzzle.slideUp(500);
                    $(".headroom").css("animation-name","slideOutUp");
                    setTimeout(function(){
                        body.addClass("state--toggled");
                        body.slideDown(500);
                        $('html, body').animate({
                            scrollTop: selector.offset().top
                        }, 500, "swing", function(){
                            $(".headroom").removeClass("headroom--pinned").addClass("headroom--unpinned").css("animation-name","");
                        });
                    },500);
                },500);
            } else {
                notice.addClass("state--active");
                clearTimeout(timeout);
                selector.find("[data-text]").removeClass("state--active");
                selector.find("[data-answer-area] [data-answer-dialog]").addClass("state--active");
                timeout = setTimeout(function(){
                    notice.removeClass("state--active");
                },3000);
            }
        });

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_base_gallery_puzzle");