(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let fns = {};

        (function fn_visual_view() {
            function fn_scroll() {
                if (doc.scrollTop() > selector.height()) {
                    if (selector.find(".wrp_comp_background .elm_item").hasClass("position--fixed")) {
                        selector.find(".wrp_comp_background .elm_item").removeClass("position--fixed");
                    }
                } else {
                    selector.find(".wrp_comp_background .elm_item").addClass("position--fixed");
                }
            }

            win.on("scroll", fn_scroll);

            fn_scroll();

            fns.fn_visual_view = fn_visual_view;
        })();

        window.lui[name] = comp;
        window.lui[name].fns = fns;
    }
})("comp_base_visual");