(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let sticky_image = selector.find("[data-sticky-image]");
        let offset = 0;

        function fn_scroll() {
            let doc_top = doc.scrollTop();
            let win_height = (win.height()/2);

            let elm_1 = sticky_image.filter(`[data-sticky-image="1"]`);
            let elm_top_1 = elm_1.closest(".elm_item").offset().top - (win_height - elm_1.height()/2);
            let elm_reveal_top_1 = elm_1.closest(".elm_item").offset().top + (0.75 * elm_1.height()) - win.height();

            let elm_2 = sticky_image.filter(`[data-sticky-image="2"]`);
            let elm_top_2 = elm_2.closest(".elm_item").offset().top - (win_height - elm_2.height()/2);

            let elm_3 = sticky_image.filter(`[data-sticky-image="3"]`);
            let elm_reveal_top_3 = elm_3.closest(".elm_item").offset().top + (0.75 * elm_3.height()) - win.height();


            if (doc_top > (selector.offset().top - win_height - elm_1.height())) {
                sticky_image.parent().css("height", `${sticky_image.parent().height()}px`);
                sticky_image.addClass("is--absolute").css({
                    "top": elm_1.closest(".elm_item").position().top,
                    "width": `${sticky_image.parent().width()}px`,
                    "left": `${sticky_image.parent().offset().left}px`
                });
                sticky_image.parent().css("transform", "");
            } else {
                sticky_image.parent().css("height", "");
                sticky_image.removeClass("is--absolute").css({
                    "width": "",
                    "left": "",
                    "top": ""
                });
            }

            if (doc_top > elm_reveal_top_1) {
                elm_1.parent().css("height", "");
                sticky_image.removeClass("is--absolute").addClass("is--sticky").css({
                    "position": "",
                    "top": ""
                });
                elm_1.removeClass("is--sticky").css({
                    "width": "",
                    "left": ""
                });
                elm_1.closest(".elm_item").addClass("is--revealed");

                let elm_2_offset = -(elm_top_2 - elm_top_1);

                elm_2.removeClass("is--sticky").css({
                    "width": "",
                    "left": ""
                }).parent().css({
                    "height": "",
                    "transform": `translateY(${elm_2_offset}px)`
                });
            } else {
                sticky_image.removeClass("is--sticky");
                elm_1.closest(".elm_item").removeClass("is--revealed");
            }

            if (doc_top > elm_top_1) {

                let elm_2_offset = -((elm_top_2 - elm_top_1) - ((doc_top - elm_top_1) / 1.5));

                let offset_mobile = 0;
                if (win.width() > 959) {
                    offset_mobile = -170;
                } else {
                    offset_mobile = -64;
                }

                if (elm_2_offset < offset_mobile) {
                    offset = elm_2_offset;
                }

                elm_2.removeClass("is--sticky").css({
                    "width": "",
                    "left": ""
                }).parent().css({
                    "height": "",
                    "transform": `translateY(${offset}px)`
                });
            } else {
            }

            if (doc_top > elm_top_2) {
                elm_2.closest(".elm_item").addClass("is--revealed");
            } else {
                elm_2.closest(".elm_item").removeClass("is--revealed");
            }


            if (doc_top > elm_reveal_top_3) {
                elm_3.closest(".elm_item").addClass("is--revealed");
                elm_3.parent().css("height", "");
                elm_3.removeClass("is--sticky").css({
                    "width": "",
                    "left": ""
                });
            } else {
                elm_3.closest(".elm_item").removeClass("is--revealed");
            }
        }

        cssLoaded(function(){
            win.on("scroll", function() {
                if (win.width() >= 768) {
                    fn_scroll();
                }
            });
            win.on("resize", function() {
                if (win.width() >= 768) {
                    fn_scroll();
                }
            });
        });

        window.lui[name] = comp;
    }
})("comp_block_process");